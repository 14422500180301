<template>
    <div class="login_index">
        <el-card class="login_card">
            <!-- <div class="title">医疗废物智慧管理平台</div> -->
            <div class="title">IMWS智慧医废管理系统</div>


            <div class="sub_title">欢迎登录</div>
            <el-form ref="ruleForm" :model="form" :rules="rules">
                <el-form-item prop="account" style="margin-bottom: 30px;">
                    <el-input v-model="form.account" size="medium" prefix-icon="el-icon-user" placeholder="请输入登录账号" />
                </el-form-item>
                <el-form-item prop="password" style="margin-bottom: 30px;">
                    <el-input v-model="form.password" size="medium" prefix-icon="el-icon-key" placeholder="请输入登录密码"
                        show-password @keyup.enter.native="login" />
                </el-form-item>
            </el-form>
            <el-button type="primary" size="medium" style="width: 100%;margin-bottom: 10px;" @click="login"> 登录
            </el-button>
            <div class="img">
                <!-- <img src="http://yifei-php-admin.tjjtln.com/static/admin/assets/images/kaizen.png" /> -->
            </div>
        </el-card>
    </div>
</template>
<script>
    import * as API from '@/utils/index.js'


    import {
        addrouterrouter
    } from '@/router/index.js'

    export default {
        data() {
            return {
                form: {
                    // account: 'yifeng',
                    // password: 'yifeng654321'
                    // account: 'test',
                    // password: '123456',
                    account: '',
                    password: ''
                },
                rules: {
                    account: [],
                    password: []
                }
            }
        },
        created() {
            let token = localStorage.getItem('token')
            if (token) {
                // this.$router.push('/home')
                var routes = JSON.parse(localStorage.getItem('user')).menu
                // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', routes)
                addrouterrouter(routes)

                // this.$router.push('/pages/Home') //跳转界面

                this.jump()
            }
        },


        methods: {

            jump() {

                this.getPurviewList()

                this.$router.push("/pages/Home")

                // this.$router.push("/pages/Staff/StaffList")

                // var routes = JSON.parse(localStorage.getItem('user')).menu
                // this.formatTree([routes[0]], 0)

            },

            // formatTree(tree, val) {
            //     if (!tree || !tree.length) return;

            //     for (const node of tree) {

            //         node.level = val

            //         if (node.children && node.children.length) {

            //             this.formatTree(node.children, val + 1)

            //         } else {

            //             // console.log("nodenodenodenodenodenode", node);

            //             // this.$router.push('/pages/Home') //跳转界面
            //             this.$router.push(node.url)

            //             setTimeout(() => {
            //                 this.$router.go(0)
            //             }, 100)

            //             return

            //         }
            //     }

            //     return tree
            // },

            getUserInfo() {
                var a = JSON.parse(localStorage.getItem('user')).id
                this.$http.get('/api/admin/' + a).then((res) => {
                    if (res.code == 200) {
                        var a = JSON.parse(
                            localStorage.getItem('user')
                        )
                        var b = Object.assign({}, a, res.data);
                        localStorage.setItem('user', JSON.stringify(b)) //保存数据
                        // localStorage.setItem('user', JSON.stringify(res.data)) //保存数据
                        this.$message.success(res.msg);
                        // this.$router.push('/home')

                        this.jump()

                    }
                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },


            login() {
                // 登录接口
                API.loginAPI({
                        username: this.form.account,
                        password: this.form.password
                    })
                    .then((res) => {
                        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                        if (res.code != 200) {
                            //请求错误
                            this.$message.error(res.msg)
                            return
                        }

                        if (res.code == 200) {
                            // 登录成功
                            localStorage.setItem('token', res.data.token) //保存数据

                            // localStorage.setItem('user', JSON.stringify(res.data)) //保存数据


                            console.log("!!!!!!!!!!!!", res.data.menu && res.data.menu.length > 0);

                            if (res.data.menu && res.data.menu.length > 0) {

                            } else {

                                this.$message.error("没有权限，请联系管理员")

                                return
                            }




                            var a = res.data

                            a.menu = a.menu[0].children

                            a.menu.unshift({
                                icon: "el-icon-user",
                                leaf: false,
                                level: 0,
                                name: "个人中心",
                                route: "/pages/User/User",
                                type: 1,
                                url: "/pages/User/User",
                            })


                            // a.menu.unshift({
                            //     icon: "el-icon-user",
                            //     leaf: false,
                            //     level: 0,
                            //     name: "会员管理<<<<—————",
                            //     route: "/pages/member/member",
                            //     type: 1,
                            //     url: "/pages/member/member",
                            //     children: [{
                            //             icon: "el-icon-user",
                            //             leaf: false,
                            //             level: 0,
                            //             name: "会员基础信息",
                            //             route: "/pages/member/memberInfo",
                            //             type: 1,
                            //             url: "/pages/member/memberInfo",
                            //         },
                            //         {
                            //             icon: "el-icon-user",
                            //             leaf: false,
                            //             level: 0,
                            //             name: "会员开通记录",
                            //             route: "/pages/member/memberRecord",
                            //             type: 1,
                            //             url: "/pages/member/memberRecord",
                            //         },
                            //         {
                            //             icon: "el-icon-user",
                            //             leaf: false,
                            //             level: 0,
                            //             name: "会员信息设置<--------",
                            //             route: "/pages/member/memberSet",
                            //             type: 1,
                            //             url: "/pages/member/memberSet",
                            //         },
                            //         {
                            //             icon: "el-icon-user",
                            //             leaf: false,
                            //             level: 0,
                            //             name: "订单列表<--------",
                            //             route: "/pages/member/orderList",
                            //             type: 1,
                            //             url: "/pages/member/orderList",
                            //         },
                            //         {
                            //             icon: "el-icon-user",
                            //             leaf: false,
                            //             level: 0,
                            //             name: "发票列表<--------",
                            //             route: "/pages/member/invoiceList",
                            //             type: 1,
                            //             url: "/pages/member/invoiceList",
                            //         },
                            //     ]
                            // })


                            a.menu.unshift({
                                icon: "el-icon-house",
                                leaf: false,
                                level: 0,
                                name: "首页",
                                route: "/pages/Home",
                                type: 1,
                                url: "/pages/Home",
                            })


                            localStorage.setItem('user', JSON.stringify(a)) //保存数据


                            // var a = res.data
                            // var b = res.data.menu.map(x => {
                            //     return x.name
                            // })
                            // if (!(b.includes("个人中心"))) {
                            //     a.menu.unshift({
                            //         icon: "el-icon-user",
                            //         leaf: false,
                            //         level: 0,
                            //         name: "个人中心",
                            //         route: "/pages/User/User",
                            //         type: 1,
                            //         url: "/pages/User/User",
                            //     })
                            // }
                            // if (!(b.includes("首页"))) {
                            //     a.menu.unshift({
                            //         icon: "el-icon-house",
                            //         leaf: false,
                            //         level: 0,
                            //         name: "首页",
                            //         route: "/pages/Home",
                            //         type: 1,
                            //         url: "/pages/Home",
                            //     })
                            // }
                            // localStorage.setItem('user', JSON.stringify(a)) //保存数据








                            var routes = JSON.parse(localStorage.getItem('user')).menu
                            // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', routes)
                            addrouterrouter(routes)

                            this.getUserInfo()

                            // this.$router.push('/home') //跳转界面
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }

            // this.$router.push('/home') //跳转界面

            // localStorage.setItem("key", "value")

            // let a = localStorage.getItem("key");
            // console.log(a)

            // localStorage.removeItem("key")
            // localStorage.clear()

            // JSON.stringify(data)
            // JSON.parse(data)

            // this.$http.post('/api/login/index', {
            //     username: this.form.account,
            //     password: this.form.password
            // }).then((res) => {

            //     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

            //     // console.log(res)

            //     // if (res.code != 200) { //请求错误
            //     //     this.$message.error(res.msg);
            //     //     return;

            //     // }
            //     // // 登录成功
            //     // localStorage.setItem("token", res.data.token) //保存数据
            //     // this.$router.push('/home') //跳转界面

            // }).catch((err) => {
            //     console.log(err)
            // });
        }
    }
</script>
<style lang="scss" scoped>
    .login_index {
        // background: url('http://yifei-php-admin.tjjtln.com/static/admin/assets/images/bg-login.png') no-repeat;
        background: url('../../../public/bg-login.png') no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        width: 100vw;
        height: 100vh;

        .login_card {
            width: 480px;
            max-width: 100%;

            background-color: #ffffff80;
            position: fixed;
            right: 300px;
            top: calc(50% - 225px);


            // position: fixed;
            // left: 50%;
            // top: 50%;

            // transform: translateX(-50%) translateY(-50%);

            padding: 48px;


            // border: 1px red solid;
            // box-sizing: border-box;


            /deep/.el-button,
            /deep/.el-form {

                // position: relative;
                // left: 50%;
                // transform: translateX(-50%);

                // border: 1px red solid;
                // box-sizing: border-box;

                // width: 85% !important;

                // margin-bottom: 10px;



            }

            /deep/.el-button,
            /deep/.el-input__inner,
            /deep/.el-input {

                height: 48px;

            }







            .title {
                font-size: 30px;
            }

            .sub_title {
                margin: 30px 0;
                font-size: 24px;
            }

            .img {
                display: flex;
                justify-content: center;
                margin-top: 30px;
            }
        }
    }
</style>